import React, { useState } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

const BACKEND_API = 'http://localhost:5000/api/';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [wallet, setWallet] = useState('');
  const [message, setMessage] = useState('Please sign this message to verify your wallet.');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referredBy = searchParams.get('ref');

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!wallet) {
      toast.success('Please connect your wallet', {
        position: "bottom-center"
      });
      return;
    }
    try {
      const response = await axios.post(BACKEND_API + 'register', {
        email,
        password,
        wallet,
        referredBy
      });
      toast.success('Registration successful', {
        position: "bottom-center"
      });
      navigate('/login');
    } catch (error) {
      toast.error('Registration failed', {
        position: "bottom-center"
      });
    }
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        const accounts = await web3.eth.getAccounts();
        const wallet = accounts[0];

        setWallet(wallet);

        const signature = await web3.eth.personal.sign(message, wallet, '');
        console.log('Signed message:', signature);

        // You can send the signature to your server here if needed
        // const response = await axios.post('/api/verify-signature', { signature });
      } catch (error) {
        console.error('User denied account access', error);
      }
    } else {
      toast.success('MetaMask is not installed. Please install it to use this feature.', {
        position: "bottom-center"
      });
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <form onSubmit={handleRegister}>
            <div className="card">
              <div className='card-header'>
                <h1 className="card-title text-center">Register</h1>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="wallet" className="form-label">Wallet Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="wallet"
                    placeholder="Wallet Address"
                    value={wallet}
                    readOnly
                  />
                </div>
                <div className="d-grid gap-2">
                  <button type="button" className="btn btn-primary" onClick={connectWallet}>Connect Wallet</button>
                </div>
                <p className="mt-3 text-center">Do you have an account? <Link to="/login">Login here</Link></p>
              </div>
              <div className='card-footer'>
                <button type="submit" className="btn btn-success w-100">Register</button>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
