// Home.js
import React from 'react';
import MarketPage from './market';
import fingerprintIcon from '../assets/icons/fingerprint-16.svg';
import { Link } from 'react-router-dom';

const Home = () => {

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-4">
            <div class="crypto-header-info">
              <h1 class="text-white">Welcome to UniTouch        </h1>
              <h2>Mint Tokens for Everyone, Absolutely Free! 🌐💫</h2>
              <p>Join the revolution with UniTouch, where minting tokens is as easy as a touch. Enjoy the freedom to mint up to 20 tokens per person with just a fingerprint. Empower yourself in the world of cryptocurrency—simple, fast, and secure.</p>
              <Link to="/account">
                <img src={fingerprintIcon} alt="Fingerprint Icon" width="100" height="100" className="svg-icon" />


                <div style={{ textAlign: 'center', fontSize: '38px', color: 'silver' }}>MINT</div>
              </Link>

            </div>
          </div>

        </div>


      </div><div className="row d-flex flex-wrap align-items-center">
        {/* Start column */}
        <div className="col-xl-6 col-lg-6 col-md-6">
          <div className="price-box-inner">
            <h5>Blockchain Information</h5>
            <div className="table-responsive">
              <table className="table table-borderless text-white tableInfo">
                <tbody>
                  <tr>
                    <td>Consensus</td>
                    <td>:</td>
                    <td>HPOS</td>
                  </tr>
                  <tr>
                    <td>Performance</td>
                    <td>:</td>
                    <td>TPS +7000 “ Average Block Interval 3s</td>
                  </tr>
                  <tr>
                    <td>Compatible</td>
                    <td>:</td>
                    <td>EVM “Ethereum Virtual Machine”</td>
                  </tr>
                  <tr>
                    <td>Crosschain</td>
                    <td>:</td>
                    <td>Supported</td>
                  </tr>
                  <tr>
                    <td>Token Contract</td>
                    <td>:</td>
                    <td>Supported</td>
                  </tr>
                  <tr>
                    <td>Smart Contract</td>
                    <td>:</td>
                    <td>Supported</td>
                  </tr>
                  <tr>
                    <td>Smart Contract Type</td>
                    <td>:</td>
                    <td>ERC20 &amp; BSC20</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6">
          <div className="info-images">
            <img src="img/feature/pasa2.png" alt="Blockchain Illustration" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
