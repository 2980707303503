import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import ERC20Abi from '../data/ERC20Abi.json';

const SwapPage = ({ isDevMode = false }) => {
  const [amountA, setAmountA] = useState('');
  const [amountB, setAmountB] = useState('');
  const [pair, setPair] = useState({ tokenA: 'ETH', tokenB: 'USDT' });
  const [rate, setRate] = useState(0);
  const [isBuy, setIsBuy] = useState(true);
  const [isApproving, setIsApproving] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(''); // Added approval status

  const tokens = {
    ETH: { symbol: 'Ξ', name: 'Ethereum', isERC20: false },
    USDT: { symbol: '$', name: 'Tether', isERC20: true, contractAddress: '0x...' }, // Add contract address
  };

  useEffect(() => {
    const fetchRate = async () => {
      try {
        if (isDevMode) {
          setRate(2000); // Example rate for development mode
        } else {
          const response = await fetch(
            `https://api.example.com/rate?pair=${pair.tokenA}_${pair.tokenB}`
          );
          if (!response.ok) {
            throw new Error('Failed to fetch rate');
          }
          const data = await response.json();
          if (data && data.rate) {
            setRate(data.rate);
          } else {
            throw new Error('Invalid rate data');
          }
        }
      } catch (error) {
        console.error('Error fetching rate:', error);
        setRate(2000); // Fallback rate
      }
    };

    fetchRate();
  }, [pair, isDevMode]);

  const handleAmountChange = (e, isTokenA) => {
    const value = e.target.value;
    if (isTokenA) {
      setAmountA(value);
      setAmountB((value * rate).toFixed(6));
    } else {
      setAmountB(value);
      setAmountA((value / rate).toFixed(6));
    }
  };

  const handlePairChange = (e) => {
    const { name, value } = e.target;
    setPair((prevPair) => {
      const newPair = {
        ...prevPair,
        [name]: value,
        [name === 'tokenA' ? 'tokenB' : 'tokenA']: prevPair[name],
      };

      setIsBuy(newPair.tokenA === 'ETH');

      return newPair;
    });
  };

  const handleApprove = async () => {
    try {
      if (!window.ethereum) throw new Error('MetaMask is not installed');

      const web3 = new Web3(window.ethereum);
      const tokenContract = new web3.eth.Contract(ERC20Abi, tokens[pair.tokenA].contractAddress);

      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];

      const amountToApprove = web3.utils.toWei(amountA, 'ether'); // Adjust decimals as per token

      setIsApproving(true);
      setApprovalStatus('Pending...');

      await tokenContract.methods.approve('0xYourSpendingContractAddress', amountToApprove)
        .send({ from: account });

      setApprovalStatus('Approved');
      setIsApproving(false);
      handleSwap();
    } catch (error) {
      console.error('Error during approval:', error);
      setApprovalStatus('Failed');
      setIsApproving(false);
    }
  };

  const handleSwap = () => {
    // Perform the swap after approval
    console.log('Swapping tokens:', pair.tokenA, 'to', pair.tokenB);
    // Add actual swap logic here...
  };

  const handleSwapClick = () => {
    if (!isBuy && tokens[pair.tokenA].isERC20) {
      // If selling an ERC20 token, show approval modal
      handleApprove();
    } else {
      // Directly perform the swap if no approval needed
      handleSwap();
    }
  };

  return (
    <div className="container d-flex justify-content-center">
      <div className="col-12 col-lg-6 mb-3 pt-3">
        <div className='card'>
          <div className='card-header'>
            <h3>{isBuy ? 'BUY' : 'SELL'}</h3>
          </div>
          <div className='card-body'>
            {/* Pair Selection */}
            <div className="input-group mb-3">
              <select
                name="tokenA"
                value={pair.tokenA}
                onChange={handlePairChange}
                className="form-select"
              >
                {Object.keys(tokens).map((token) => (
                  <option key={token} value={token}>
                    {tokens[token].name} ({tokens[token].symbol})
                  </option>
                ))}
              </select>
              <span className="input-group-text">to</span>
              <select
                name="tokenB"
                value={pair.tokenB}
                onChange={handlePairChange}
                className="form-select"
              >
                {Object.keys(tokens).map((token) => (
                  <option key={token} value={token}>
                    {tokens[token].name} ({tokens[token].symbol})
                  </option>
                ))}
              </select>
            </div>

            {/* Amount Input and Calculation */}
            <div className="input-group mb-1">
              <input
                type="number"
                placeholder={`Amount of ${pair.tokenA}`}
                value={amountA}
                onChange={(e) => handleAmountChange(e, true)}
                className="form-control"
              />
              <span className="input-group-text">{tokens[pair.tokenA].symbol}</span>
            </div>

            <div className="input-group mb-1">
              <input
                type="number"
                placeholder={`Amount of ${pair.tokenB}`}
                value={amountB}
                onChange={(e) => handleAmountChange(e, false)}
                className="form-control"
              />
              <span className="input-group-text">{tokens[pair.tokenB].symbol}</span>
            </div>
          </div>
          <div className='card-footer'>
            <button
              className="btn btn-success w-100 mt-1"
              onClick={handleSwapClick}
              disabled={isApproving}
            >
              {isApproving ? 'Approving...' : `${isBuy ? 'BUY' : 'SELL'} ${pair.tokenA} for ${pair.tokenB}`}
            </button>
          </div>
        </div>


        <div className='card mt-2'>
          <div className='card-body'>
            <div className="form-group mt-3">
              Minimal you receive {amountB} {tokens[pair.tokenB].symbol}
            </div>
            <div className="form-group mt-3">
              Transaction Slippage is {isBuy ? '0%' : '0.25%'} by default
            </div>

            {/* Approval Status */}
            {approvalStatus && (
              <div className="form-group mt-3">
                Approval Status: {approvalStatus}
              </div>
            )}
          </div>
        </div>





        {/* Approval Modal (Simplified for Demo) */}
        {isApproving && (
          <div className="modal">
            <div className="modal-content">
              <p>Approving {pair.tokenA}...</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SwapPage;
