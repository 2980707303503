import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const MarketPage = () => {
    const [selectedCoin, setSelectedCoin] = useState('BTCUSDT'); // Default to BTC/USDT
    const [coins, setCoins] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Track chart loading state

    // Fetch list of coins from CoinGecko (or any API)
    useEffect(() => {
        fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false')
            .then((response) => response.json())
            .then((data) => {
                setCoins(data);
            })
            .catch((error) => console.error('Error fetching coin data:', error));
    }, []);

    // Load the TradingView widget when the component mounts and when the selected coin changes
    useEffect(() => {
        setIsLoading(true); // Show loading spinner before the chart loads
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/tv.js';
        script.onload = () => {
            loadTradingViewWidget();
        };
        document.body.appendChild(script);
    }, [selectedCoin]);

    // Function to initialize TradingView widget
    const loadTradingViewWidget = () => {
        if (window.TradingView) {
            new window.TradingView.widget({
                width: '100%',
                height: 500,
                symbol: `BINANCE:${selectedCoin}`,
                interval: 'D',
                timezone: 'Etc/UTC',
                theme: 'light',
                style: '1',
                locale: 'en',
                toolbar_bg: '#f1f3f6',
                enable_publishing: false,
                allow_symbol_change: true,
                container_id: 'tradingview_chart',
                onReady: () => {
                    setIsLoading(false); // Hide spinner when chart is ready
                  },
            });
        }
    };

    // Load the TradingView widget every time the selectedCoin changes
    useEffect(() => {
        loadTradingViewWidget();
    }, [selectedCoin]);

    const handleCoinSelect = (e) => {
        setSelectedCoin(e.target.value);
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-4">Crypto Market Prices</h1>
            <div className="row">
                {/* Left Side: Coin Selection */}
                <div className="col-md-4">
                    <h4>Select a Coin</h4>
                    <select className="form-select" onChange={handleCoinSelect} value={selectedCoin}>
                        {coins.map((coin) => (
                            <option key={coin.id} value={`${coin.symbol.toUpperCase()}USDT`}>
                                {coin.name} ({coin.symbol.toUpperCase()})
                            </option>
                        ))}
                    </select>
                    <div className="mt-4">
                        <h5>Market Prices</h5>
                        <ul className="list-group">
                            {coins.map((coin) => (
                                <li key={coin.id} className="list-group-item d-flex justify-content-between align-items-center">
                                    {coin.name}
                                    <span className="badge bg-primary rounded-pill">${coin.current_price.toLocaleString()}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Right Side: TradingView Widget */}
                <div className="col-md-8">
                    <h4>{selectedCoin} Chart    </h4>
                    {/* Show loading spinner while chart is loading */}
                
                    {/* {isLoading ? (
                        <div className="d-flex justify-content-center my-5">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : null} */}

                    <div id="tradingview_chart" />
                </div>
            </div>
        </div>
    );
};

export default MarketPage;
