import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../styles/login.css'

const BACKEND_API = 'http://localhost:5000/api/';

const Login = () => {
  const [email, setEmail] = useState(''); // Change username to email
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(BACKEND_API + `login`, {
        email, // Use email instead of username
        password
      });
      localStorage.setItem('token', response.data.token);

      toast.info('Login successful', {
        position: "bottom-center"
      });
      navigate('/account');
    } catch (error) {

      toast.error('Login failed', {
        position: "bottom-center"
      });
    }
  };

  return (
    <div id='login'>
      <div className="container" >
        <div id="login-row" className="row justify-content-center align-items-center">
          <div id="login-column" className="col-md-6">
            <div id="login-box" className="col-md-12">
              <form onSubmit={handleLogin}  id="login-form" className="form">
              <h3 className="text-center text-info">Login</h3>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary btn-block w-100">Login</button>
              </form>
              <p className="mt-3">Don't have an account? <Link to="/register">Register here</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
