// Home.js
import React from 'react';

const Contact = () => {

  return (
    <div>
      <h1>Contact</h1>
    </div>
  );
}

export default Contact;
