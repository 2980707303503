// Home.js
import React from 'react';

const About = () => {

  return (
    <div>
      <h1>about</h1>
    </div>
  );
}

export default About;
