// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './pages/register';
import Login from './pages/login';
import Home from './pages/home';
import Token from './pages/token';
import SwapPage from './pages/swap';
import MarketPage  from './pages/market';
import TokenSalePage from './pages/tokensale';
import About from './pages/about';
import Contact from './pages/contact';
import Airdrop from './pages/airdrop';
import Account from './pages/account';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar';
import { WebSocketProvider } from './contexts/WebSocketContext';
import './App.css';

function App() {
  return (
    <Router>
      <WebSocketProvider>
        <div className="App">
          <Navbar logoPosition='left' wsStatus={false} />
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/account" element={<Account />} />
            <Route path="/token" element={<Token />} />
            <Route path="/swap" element={<SwapPage />} />
            <Route path="/tokensale" element={<TokenSalePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/airdrop" element={<Airdrop />} />
            <Route path="/market" element={<MarketPage />} />
            <Route path="/" element={<Home />} />
          </Routes>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </WebSocketProvider>
    </Router>
  );
}

export default App;
