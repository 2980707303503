// Home.js
import React, { useEffect, useState } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { BACKEND_API, TOKENADDRESS } from '../config';

const Airdrop = () => {
  const botUsername = 'unitouchbot'; // Telegram bot username
  const [fingerprint, setFingerprint] = useState('');
  const userToken = localStorage.getItem('token'); // فرض می‌کنیم توکن کاربر در localStorage ذخیره شده است

  // Function to redirect to Telegram bot
  const redirectToTelegram = () => {
    window.location.href = `https://t.me/${botUsername}`;
  };

  // Function to send the fingerprint and token to the backend
  const sendFingerprintToBackend = async (fingerprint, token) => {
    try {

      const response = await fetch(`${BACKEND_API}/submit-fingerprint`, { // Replace with your backend URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // اضافه کردن توکن به هدر
        },
        body: JSON.stringify({ fingerprint }),
      });

      if (response.ok) {
        alert('Fingerprint and token submitted successfully!');
      } else {
        alert('Failed to submit fingerprint and token.');
      }
    } catch (error) {
      console.error('Error submitting fingerprint and token:', error);
      alert('An error occurred while submitting the fingerprint and token.');
    }
  };

  useEffect(() => {
    // Generate the fingerprint when the component mounts
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const fingerprintCode = result.visitorId;
      setFingerprint(fingerprintCode);
    };

    loadFingerprint();
  }, []);

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: '0 auto', textAlign:'left' }}>
      <h1>Airdrop Campaign</h1>
      <p>
        Welcome to our exciting Airdrop campaign! This is your chance to win exclusive rewards by participating in a fun and engaging game directly within the Telegram app.
      </p>

      <h2>What is the "Finger in Telegram" Game?</h2>
      <p>
        The "Finger in Telegram" game is a unique challenge designed to test your speed, reflexes, and endurance. Here's how it works:
      </p>
      <ul>
        <li>Join the game by interacting with our Telegram bot.</li>
        <li>Once the game starts, you will need to keep your finger pressed on a virtual button within the Telegram app.</li>
        <li>The last person to remove their finger will be declared the winner!</li>
      </ul>
      <p>
        It's a simple yet thrilling game where the goal is to outlast everyone else to win amazing rewards.
      </p>

      <h2>How to Participate?</h2>
      <p>
        Follow these easy steps to join the airdrop and participate in the game:
      </p>
      <ol>
        <li>Click the button below to go to our Telegram bot <strong>@{botUsername}</strong>.</li>
        <li>Follow the instructions provided by the bot to register for the game.</li>
        <li>Wait for the game to start and keep your finger on the virtual button as long as possible.</li>
        <li>The last person to release their finger wins the game!</li>
      </ol>

      <h2>Rewards</h2>
      <p>
        Winners of the "Finger in Telegram" game will receive fantastic airdrop rewards including tokens, NFTs, and other special prizes. The longer you stay in the game, the higher your chances of winning!
      </p>

      <p>
        Don't miss out on this fun and rewarding experience. Click the button below to join the game and test your endurance!
      </p>

      <button 
        onClick={redirectToTelegram} 
        style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', backgroundColor: '#0088cc', color: '#fff', border: 'none', borderRadius: '5px' }}
      >
        Go to Telegram Bot
      </button>

      <button 
        onClick={() => sendFingerprintToBackend(fingerprint, userToken)} 
        style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', float:'right', marginTop: '20px', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '5px' }}
        disabled={!userToken} // اگر توکن موجود نباشد، دکمه غیرفعال شود
      >
        Submit Fingerprint
      </button>
    </div>
  );
}

export default Airdrop;
