import React, { useState, useEffect } from 'react';
import { Web3 } from 'web3';
import TokenSaleABI from '../data/TokenSaleABI.json'; // Path to your TokenSale ABI JSON
import { TOKENSALEADDRESS } from '../config'; // Path to your configuration file

const TokenSalePage = ({ isDevMode = false }) => {
  const [amount, setAmount] = useState('');
  const [rate, setRate] = useState(isDevMode ? 2000 : 0); // Default rate for development
  const [totalTokensSold, setTotalTokensSold] = useState('0');
  const [targetAmount, setTargetAmount] = useState('0');
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [web3, setWeb3] = useState(null); // Local web3 state
  const [account, setAccount] = useState('');

  useEffect(() => {
    const initWeb3 = async () => {
      try {
        // Initialize Web3
        const web3Instance = new Web3(Web3.givenProvider || "http://localhost:8545");
        setWeb3(web3Instance);

        const accounts = await web3Instance.eth.getAccounts();
        const tokenSale = new web3Instance.eth.Contract(TokenSaleABI, TOKENSALEADDRESS);
        // const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);

        // Fetch contract details
        const fetchContractDetails = async () => {
          try {
            const details = await tokenSale.methods.getSaleDetails().call();
            setRate(details[0]);
            setStartTime(details[1]);
            setEndTime(details[2]);
            setTotalTokensSold(web3Instance.utils.fromWei(details[3], 'ether'));
            setTargetAmount(web3Instance.utils.fromWei(details[4], 'ether'));
          } catch (error) {
            console.error('Error fetching contract details:', error);
            setError('Error fetching contract details');
          }
        };

        fetchContractDetails();
      } catch (error) {
        console.error('Error initializing Web3:', error);
        setError('Error initializing Web3');
      }
    };

    initWeb3();
  }, [isDevMode]);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleBuyTokens = async () => {
    if (!amount || isNaN(amount) || amount <= 0) {
      setError('Invalid amount');
      return;
    }

    setIsProcessing(true);
    setError('');

    try {

      // Check if MetaMask is installed and accessible
      if (!window.ethereum) {
        throw new Error('MetaMask is not installed');
      }

      if (!web3) throw new Error('Web3 not initialized');


      const tokenSale = new web3.eth.Contract(TokenSaleABI, TOKENSALEADDRESS);

      // Fetch and log the user's ETH balance
      const balance = await web3.eth.getBalance(account);
      const balanceInEth = web3.utils.fromWei(balance, 'ether');
      console.log(`Account balance: ${balanceInEth} ETH`);

      // Check if the user has enough ETH balance for the transaction
      if (parseFloat(balanceInEth) < parseFloat(amount)) {
        setError('Insufficient ETH balance');
        return;
      }


      // Log the TokenSale contract address
      console.log(`TokenSale Contract Address: ${TOKENSALEADDRESS}`);

      // Get the current block's timestamp
      const currentBlock = await web3.eth.getBlock('latest');
      const currentBlockTimestamp = currentBlock.timestamp;
      console.log(`Current block timestamp: ${currentBlockTimestamp}`);

      // Fetch startTime from the tokenSale contract (assuming startTime is a public state variable)
      const startTime = await tokenSale.methods.startTime().call();
      console.log(`TokenSale start time: ${startTime}`);

      if (parseInt(currentBlockTimestamp) > parseInt(endTime)) {
        setError('Token sale has ended');
        return;
      }

      const gasEstimate = await tokenSale.methods.buyTokens().estimateGas({
        from: account,
        value: web3.utils.toWei(amount, 'ether')
      });

      console.log(`Estimated gas: ${gasEstimate}`);
      await tokenSale.methods.buyTokens().send({
        from: account,
        value: web3.utils.toWei(amount, 'ether'),
        gas: gasEstimate // example gas limit
      });

      alert('Tokens purchased successfully');
      setAmount('');
    } catch (error) {
      console.error('Error buying tokens:', error);
      setError('Error buying tokens');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRefund = async () => {
    setIsProcessing(true);
    setError('');

    try {

      if (!web3) throw new Error('Web3 not initialized');

      const accounts = await web3.eth.getAccounts();
      const tokenSale = new web3.eth.Contract(TokenSaleABI, TOKENSALEADDRESS);

      await tokenSale.methods.issueRefund().send({
        from: accounts[0]
      });

      alert('Refund issued successfully');
    } catch (error) {
      console.error('Error issuing refund:', error);
      setError('Error issuing refund');
    } finally {
      setIsProcessing(false);
    }
  };

  // Utility function to convert Wei to ETH
  const weiToEth = (wei) => {
    if (!web3 || !web3.utils) return '0'; // Return '0' if web3 or web3.utils is not initialized
    try {
      return web3.utils.fromWei(wei.toString(), 'ether'); // Ensure wei is a string
    } catch (error) {
      console.error('Error converting Wei to ETH:', error);
      return '0';
    }
  };

  return (
    <div className="container d-flex justify-content-center">
      <div className="col-12 col-lg-6 mb-3 pt-3">
        <div className='card'>
          <div className='card-header'>
            <h3>Token Sale</h3>
          </div>
          <div className='card-body'>
            {error && <div className="alert alert-danger">{error}</div>}

            <div className="form-group mb-3">
              <label htmlFor="amount">Amount (ETH):</label>
              <input
                type="number"
                id="amount"
                value={amount}
                onChange={handleAmountChange}
                className="form-control"
                placeholder="Enter amount in ETH"
              />
            </div>


            <button
              className="btn btn-primary w-100 mb-3"
              onClick={handleBuyTokens}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : 'Buy Tokens'}
            </button>

            <button
              className="btn btn-danger w-100"
              onClick={handleRefund}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : 'Request Refund'}
            </button>
          </div>
          {/* <div className='card-footer'>
            <p><strong>Rate:</strong> {rate} tokens per ETH</p>
            <p><strong>Total Tokens Sold:</strong> {totalTokensSold}</p>
            <p><strong>Target Amount:</strong> {targetAmount} ETH</p>
            <p><strong>Sale Start Time:</strong> {new Date(startTime * 1000).toLocaleString()}</p>
            <p><strong>Sale End Time:</strong> {new Date(endTime * 1000).toLocaleString()}</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TokenSalePage;
