import * as devConfig from './config.development';
import * as prodConfig from './config.production';

let config;
if (process.env.REACT_APP_ENV === 'development') {
  config = devConfig;
} else {
  config = prodConfig;
}

export const { BACKEND_API, TOKENADDRESS, TOKENSALEADDRESS, SWAP1INCHADDRESS, SWAPUNITADDRESS, CHAINID } = config;
