// WebSocketContext.js
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [status, setStatus] = useState('Connecting...');
  const connection = useRef(null);

  const connectWebSocket = () => {
    const socket = new WebSocket('ws://127.0.0.1:7071');

    socket.onopen = () => {
      setStatus('Connected');
      socket.send('Connection established');
    };

    socket.onmessage = (event) => {
      console.log('Message from server', event.data);
    };

    socket.onclose = () => {
      setStatus('Disconnected');
      setTimeout(connectWebSocket, 3000); // Retry connection after 3 seconds
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setStatus('Error');
      setTimeout(connectWebSocket, 3000); // Retry connection after 3 seconds
    };

    connection.current = socket;
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (connection.current) {
        connection.current.close();
      }
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ connection, status }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
