import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaUser, FaWallet, FaSignOutAlt, FaCheck, FaCopy } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, ListGroup } from 'react-bootstrap';

import { BACKEND_API, TOKENADDRESS } from '../config';

const Account = () => {
  const [user, setUser] = useState(null);
  const [message] = useState('Please sign this message to verify your wallet.');
  const [referralLink, setReferralLink] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${BACKEND_API}profile`, {
            headers: {
              Authorization: token
            }
          });
          setUser(response.data);
          setReferralLink(`${window.location.origin}/register?ref=${response.data.referralCode}`);
        } catch (error) {
          toast.error('Failed to fetch profile', {
            position: "bottom-center"
          });
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    };
    fetchProfile();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    toast.info('Logged out successfully', {
      position: "bottom-center"
    });
    navigate('/login');
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        const accounts = await web3.eth.getAccounts();
        const wallet = accounts[0];

        const signature = await web3.eth.personal.sign(message, wallet, '');
        console.log('Signed message:', signature);

        setUser(prevUser => ({ ...prevUser, wallet }));
        toast.success('Wallet verified successfully', {
          position: "bottom-center"
        });
      } catch (error) {
        toast.error('User denied account access', {
          position: "bottom-center"
        });
      }
    } else {
      toast.error('MetaMask is not installed. Please install it to use this feature.', {
        position: "bottom-center"
      });
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    toast.info('Referral link copied to clipboard', {
      position: "bottom-center"
    });
  };

  return (
    <div className="container mt-5">
      {user ? (
        <Card>
          <Card.Header>
            <h3>
              <FaUser className="me-2" /> User Profile
            </h3>
          </Card.Header>
          <Card.Body>
            <ListGroup variant="flush">
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Username
                <span className="badge bg-primary rounded-pill">{user.username || 'Not Set'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Email
                <span className="badge bg-primary rounded-pill">{user.email}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Wallet Address
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={user.wallet || 'Not Connected'}
                    readOnly
                  />
                  {user.wallet ? (
                    <button className="btn btn-outline-success" disabled>
                      <FaCheck className="me-1" /> Verified
                    </button>
                  ) : (
                    <button className="btn btn-outline-warning" onClick={connectWallet}>
                      <FaWallet className="me-1" /> Verify Wallet
                    </button>
                  )}
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Referral Link
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={referralLink}
                    readOnly
                  />
                  <button className="btn btn-outline-secondary" onClick={copyToClipboard}>
                    <FaCopy className="me-1" /> Copy
                  </button>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
          <Card.Footer className="d-grid gap-2">
            <button className="btn btn-primary" onClick={handleLogout}>
              <FaSignOutAlt className="me-2" /> Logout
            </button>
          </Card.Footer>
        </Card>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Account;
