import { BACKEND_API, TOKENADDRESS } from '../config';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import Web3 from 'web3';
const BigNumber = require('bignumber.js');
// Example: src/pages/Token.js


const Token = () => {
  const [erc20, setErc20] = useState([]);
  const navigate = useNavigate();

  const [netId, setNetId] = useState([]);

  // Example useEffect to fetch tokens (you can modify as per your API structure)
  useEffect(() => {
    const fetchWeb3 = async () => {
      if (window.ethereum) {
        try {
          const web3 = new Web3(window.ethereum);
          const _netId = await web3.eth.net.getId().then(async x => {
            var networkName = 0;
            switch (x) {
              case "1":
                networkName = "Main";
                break;
              case "2":
                networkName = "Morden";
                break;
              case "3":
                networkName = "Ropsten";
                break;
              case "4":
                networkName = "Rinkeby";
                break;
              case "42":
                networkName = "Kovan";
                break;
              default:
                networkName = "Ganache";
            }
            return networkName;
          });

          console.log(_netId);
          setNetId(_netId);
          // You can send the signature to your server here if needed
          // const response = await axios.post('/api/verify-signature', { signature });
        } catch (error) {
          console.error('User denied account access', error);
        }
      }
    };

    fetchWeb3().then(x => {
      if (netId) {
        fetchTokenDetail();
      }
    });

    const fetchTokenDetail = async () => {

      try {
        const response = await axios.get(`${BACKEND_API}web3/token/${TOKENADDRESS}`);
        console.log('totalSupply = ', response.data.totalSupply);
        console.log(response.data)
        setErc20(response.data); // Assuming your API returns tokens in the format { tokens: [] }
      } catch (error) {
        toast.error('Error fetching tokens', {
          position: "bottom-center"
        });
        // navigate('/login');
      }

    }
  }, [navigate]); // Empty dependency array ensures this runs only once on component mount

  return (
    <div className="container">
      <div className="row">
        {/* Hands Tokens Card */}
        <div className="col-12 col-sm-12 col-md-12 pt-3">

          <Card >

            <Card.Header>Token Details</Card.Header>

            <Card.Body>
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Name
                  <span class="badge bg-primary rounded-pill">{erc20.name}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Synbol
                  <span class="badge bg-primary rounded-pill">{erc20.symbol}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Decimals
                  <span class="badge bg-primary rounded-pill">{erc20.decimals}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  total Supply
                  <span class="badge bg-primary rounded-pill">{erc20.totalSupply}</span>
                </li>              
              </ul>

            </Card.Body>

          </Card>
          
          <Card className='mt-3'>

            <Card.Header>Token Functions</Card.Header>

            <Card.Body>
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Tax
                  <span class="badge bg-primary rounded-pill">{erc20.name}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Burn
                  <span class="badge bg-primary rounded-pill">{erc20.symbol}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Send
                  <span class="badge bg-primary rounded-pill">{erc20.decimals}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Stake
                  <span class="badge bg-primary rounded-pill">{erc20.totalSupply}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Trade
                  <span class="badge bg-outline-secondary rounded-pill"><Link to="/swap">Swap</Link></span>
                </li>
              </ul>

            </Card.Body>

          </Card>

        </div>
      </div>
    </div>
  );
};

export default Token;
